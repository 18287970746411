import {
  REQUEST_ACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  GET_TRANSACTION_RECORDS_SUCCESS,
  GET_TRANSACTION_RECORDS_FAILURE,
  GET_SINGLE_RECORD_SUCCESS,
  GET_SINGLE_RECORD_FAILURE,
  GET_ACCOUNT_TRANSACTION_SUCCESS,
  GET_ACCOUNT_TRANSACTION_FAILURE,
  GET_CASH_FLOW_FAILURE,
  GET_CASH_FLOW_LOADING,
  GET_CASH_FLOW_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const createTransaction = (payload) => ({
  type: CREATE_TRANSACTION_SUCCESS,
  payload,
});

export const createTransactionFailure = (error) => ({
  type: CREATE_TRANSACTION_FAILURE,
  payload: error,
});

export const getAccount = (payload) => ({
  type: GET_ACCOUNT_SUCCESS,
  payload,
});

export const getAccountFailure = (payload) => ({
  type: GET_ACCOUNT_FAILURE,
  payload,
});

export const getTransactionRecords = (payload) => ({
  type: GET_TRANSACTION_RECORDS_SUCCESS,
  payload,
});

export const getTransactionRecordsFailure = (payload) => ({
  type: GET_TRANSACTION_RECORDS_FAILURE,
  payload,
});

export const getCashFlowDataSuccess = (payload) => ({
  type: GET_CASH_FLOW_SUCCESS,
  payload,
});

export const getCashFlowDataFailure = (error) => ({
  type: GET_CASH_FLOW_FAILURE,
  error,
});

export const getSingleRecord = (payload) => ({
  type: GET_SINGLE_RECORD_SUCCESS,
  payload,
});

export const getSingleRecordFailure = (payload) => ({
  type: GET_SINGLE_RECORD_FAILURE,
  payload,
});

export const getAccountTransactionSuccess = (payload) => ({
  type: GET_ACCOUNT_TRANSACTION_SUCCESS,
  payload,
});

export const getAccountTransactionFailure = (error) => ({
  type: GET_ACCOUNT_TRANSACTION_FAILURE,
  error,
});

/**
 * Create a thunk which makes an API call to create journal entry
 *
 * @param {Object} payload
 *
 * @returns {Function}
 */
export const createJournalEntry = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/accounting/journalEntry', payload);
    dispatch(createTransaction(data));

    return data;
  } catch (error) {
    dispatch(createTransactionFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get accounts book
 *
 * @returns {Function}
 */
export const getAllAccounts = (accountName) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(
      `/accounting/sub-accounts?search=${accountName}`,
    );
    dispatch(getAccount(data.data));

    return data;
  } catch (error) {
    dispatch(getAccountFailure(error?.response?.data?.error));

    return errorHandler(error);
  }
};

/**
 * Get all transaction records
 *
 * @returns {Function}
 */
export const getAllTransactionRecords = (
  limit,
  page,
  startDate,
  endDate,
) => async (dispatch) => {
  dispatch(requestAction());

  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';
  startDate = startDate ? `&from=${startDate}` : '';
  endDate = endDate ? `&to=${endDate}` : '';

  try {
    const { data } = await axios.get(
      `/accounting/transactions?${limit}${page}${startDate}${endDate}`,
    );

    dispatch(getTransactionRecords(data.data));

    return data;
  } catch (error) {
    dispatch(getTransactionRecordsFailure(error?.response?.data?.error));
    return errorHandler(error);
  }
};

/**
 * Get cash-flow records
 *
 * @returns {Function}
 */
export const getCashFlowData = (startDate, endDate, queryByName) => async (
  dispatch,
) => {
  dispatch({ type: GET_CASH_FLOW_LOADING });

  try {
    startDate = startDate ? `&from=${startDate}` : '';
    endDate = endDate ? `&to=${endDate}` : '';
    queryByName = queryByName ? `&subAccountName=${queryByName}` : '';

    const { data } = await axios.get(
      `/accounting/transactions?type=cashFlow${startDate}${endDate}${queryByName}`,
    );

    dispatch(getCashFlowDataSuccess(data));
    return data;
  } catch (error) {
    dispatch(getCashFlowDataFailure(error?.response?.data?.error));

    return errorHandler(error);
  }
};

/**
 * Get single transaction record
 *
 * @returns {Function}
 */
export const getSingleTransactionRecord = (refNo) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/accounting/transactions/${refNo}`);
    dispatch(getSingleRecord(data.data));

    return data;
  } catch (error) {
    dispatch(getSingleRecordFailure(error?.response?.data?.error));

    return errorHandler(error);
  }
};

/**
 * Get all transactions for an account
 *
 * @returns {Function}
 */
export const getAccountTransactionAction = (
  accountId,
  startDate,
  endDate,
  limit,
  pageNumber,
) => async (dispatch) => {
  dispatch(requestAction());
  try {
    startDate = startDate ? `&from=${startDate}` : '';
    endDate = endDate ? `&to=${endDate}` : '';
    const { data } = await axios.get(
      `/accounting/transactions?accountId=${accountId}${startDate}${endDate}&limit=${limit}&page=${pageNumber}`,
    );

    dispatch(getAccountTransactionSuccess(data));
    return data;
  } catch (error) {
    dispatch(getAccountTransactionFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const initialState = {
  accountData: [],
  transactionData: {},
  isLoading: false,
  updateAccountData: [],
  singleRecord: {},
  transactionRecords: [],
  cashFlowDataLoading: false,
  cashFlowData: {},
  accountTransaction: [],
  error: {},
};

/**
 * This reducer changes the account transaction state of the application
 *
 * @param {TransactionState} state
 * @param {Action} action
 *
 * @returns {TransactionState} state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionData: action.payload,
        isLoading: false,
      };
    case CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        transactionData: action.payload,
        isLoading: false,
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountData: action.payload,
        isLoading: false,
      };
    case GET_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_TRANSACTION_RECORDS_SUCCESS:
      return {
        ...state,
        transactionRecords: action.payload,
        isLoading: false,
      };
    case GET_TRANSACTION_RECORDS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_SINGLE_RECORD_SUCCESS:
      return {
        ...state,
        singleRecord: action.payload,
        isLoading: false,
      };
    case GET_SINGLE_RECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        accountTransaction: action.payload,
        isLoading: false,
      };
    case GET_ACCOUNT_TRANSACTION_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_CASH_FLOW_SUCCESS:
      return {
        ...state,
        cashFlowData: action.payload,
        cashFlowDataLoading: false,
      };
    case GET_CASH_FLOW_FAILURE:
      return {
        ...state,
        error: action.error,
        cashFlowDataLoading: false,
      };
    case GET_CASH_FLOW_LOADING:
      return {
        ...state,
        cashFlowDataLoading: true,
      };
    default:
      return state;
  }
};

export default reducer;
