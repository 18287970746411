import React, { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import Button from '../Button';
import Select from '../SelectBox';
import DatesPicker from '../DatePicker';
import InputField from '../InputField';
import SearchSelect from '../CustomSelect';
import { validationSchemaForAddingPayment } from '../SingleInvoice/validation';
import formatMoney from '../../utils/formatMoney';
import { toast } from 'react-toastify';
import useErrorHandler from '../hooks/useErrorHandler';
import { InvoiceTransactionTypes } from '../../constants';
import { Checkbox, FormControlLabel, useMediaQuery } from '@mui/material';

export default function AddPayment({
  searchAccounts,
  closeModal,
  addPaymentToAnInvoiceAction,
  invoiceDetails,
  setShowRedirectModal,
  invoiceId,
}) {
  const toastid = useRef(null);
  const history = useHistory();
  const matches = useMediaQuery('(min-width:600px)');

  const [clientAccountError, setClientAccountError] = useState(false);

  const invoiceBalance = invoiceDetails
    ? String(
        Number(invoiceDetails.invoiceDetails.amount) -
          Number(invoiceDetails.invoiceDetails.amount_paid),
      )
    : 0;

  const isEndorsementRemittance =
    invoiceDetails?.invoiceDetails?.transaction_type ===
    InvoiceTransactionTypes.POLICY_EDORSEMENT_REMITTANCE;

  const isEndorsement =
    invoiceDetails?.invoiceDetails?.transaction_type ===
    InvoiceTransactionTypes.POLICY_EDORSEMENT;

  const handleSubmit = async (values, helpers) => {
    toastid.current = toast('Processsing, please wait ...', {
      isLoading: true,
    });

    try {
      const response = await addPaymentToAnInvoiceAction(
        invoiceDetails.invoiceDetails.invoice_no,
        {
          amount: values.amountToPay.replace('₦', '').replace(/,/g, ''),
          modeOfPayment: values.modeOfPayment,
          debitAccount: values.debitAccount?.account_code_pk,
          creditAccount: values.creditAccount?.account_code_pk,
          transactionDescription: values.transactionDescription,
          chequeNumber: values.chequeNumber,
          dateOfActualPayment: values.dateOfActualPayment,
          bankName: values.bank,
        },
      );

      if (response?.error) {
        if (response?.code === 'CLIENT_ACCOUNT_MISSING') {
          toast.update(toastid.current, {
            render: response?.error,
            type: 'error',
            isLoading: false,
            autoClose: 1000,
          });

          setClientAccountError(true);

          return;
        }
      }

      if (!response?.success) {
        toast.update(toastid.current, {
          render: response?.error || 'An error occured during operation!',
          type: 'error',
          isLoading: false,
          autoClose: 3500,
        });
      } else {
        toast.update(toastid.current, {
          render: 'Action completed successfully',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });

        closeModal();
        history.push(
          `/account/invoice/${
            invoiceDetails?.invoiceDetails?.invoice_id ?? invoiceId
          }`,
        );

        if (!isEndorsementRemittance && !isEndorsement) {
          setShowRedirectModal(true);
        }

        if (helpers) {
          helpers.resetForm();
        }
      }
    } catch (e) {
      toast.update(toastid.current, {
        render: e.message || 'An error occured',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const isPartialPayment =
    invoiceDetails.invoiceDetails.status === 'PARTIAL PAYMENT';

  return (
    <Formik
      initialValues={{
        amountToPay: invoiceBalance,
        modeOfPayment: isPartialPayment
          ? invoiceDetails.invoiceDetails.mode_of_payment
          : 'Bank Transfer',
        chequeNumber: '',
        bank: '',
        debitAccount: '',
        creditAccount: '',
        transactionDescription: '',
        dateOfActualPayment: new Date(),
        captureCommission: false,
      }}
      validationSchema={validationSchemaForAddingPayment()}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        isValid,
        handleSubmit,
        errors,
        setErrors,
        resetForm,
        isSubmitting,
      }) => {
        const accts = ['Cash', 'Bank Transfer', 'Cheque'];
        const direct = values.modeOfPayment === 'Direct to Insurer';
        const isNotDirect = accts.includes(values.modeOfPayment);

        const handleInputChange = (event) => {
          const { name, value } = event.target;

          if (name === 'modeOfPayment' && value === 'Direct to Insurer') {
            setFieldValue('amountToPay', '');
          }

          if (name === 'modeOfPayment' && value !== 'Direct to Insurer') {
            setFieldValue('amountToPay', invoiceBalance);
          }

          setFieldValue(name, value);
        };

        const handleAccountSelect = (name) => (value) => {
          setFieldValue(name, value);
        };

        const handleAccountSearch = (name) => async (query) => {
          const res = await searchAccounts(query);

          if (res?.success) {
            if (name === 'debitAccount' && values.creditAccount) {
              return res.data.filter(
                (acct) =>
                  acct.account_code_pk !== values.creditAccount.account_code_pk,
              );
            } else {
              return res.data.filter(
                (acct) =>
                  acct.account_code_pk !== values.debitAccount?.account_code_pk,
              );
            }
          }
        };

        return (
          <Form>
            <section className="w-full mt-6">
              <div
                style={
                  matches
                    ? {
                        minWidth: '400px',
                      }
                    : undefined
                }
              >
                <div className="pt-4 pb-4 bg-white">
                  {clientAccountError ? (
                    <div className="py-3 bg-red-100 border-l-4 border-red-300 px-2 my-2 box-border">
                      Client account has not been created,
                      <Link
                        to="/account/general-ledger?client=1"
                        className="underline ml-1 text-red-500"
                      >
                        Create client account
                      </Link>
                    </div>
                  ) : null}
                  <div className="flex flex-col sm:flex-row gap-x-2 sm:w-full">
                    <div className="mb-4 sm:w-full">
                      <DatesPicker
                        name="dateOfActualPayment"
                        selected={values.dateOfActualPayment}
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(date) => {
                          setFieldValue('dateOfActualPayment', date);
                        }}
                        placeholder="Date Of Actual Payment"
                        required
                        dateFormat="yyyy-MM-dd"
                        setErrors={setErrors}
                        value={values.dateOfActualPayment}
                        errorMessage={errors.dateOfActualPayment}
                      />
                    </div>
                    <div className="mb-4 sm:w-full">
                      <Select
                        name="modeOfPayment"
                        description="Select Mode of Payment"
                        style={{
                          backgroundPosition: '97% 50%',
                        }}
                        options={[
                          'Cash',
                          'Bank Transfer',
                          'Cheque',
                          'Direct to Insurer',
                        ]}
                        value={values.modeOfPayment}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        edited={touched}
                        errorMessage={errors.modeOfPayment}
                        disabled={isPartialPayment}
                      />
                    </div>
                  </div>
                  {direct ? (
                    <div className="mb-4">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.captureCommission}
                            onChange={(e, checked) =>
                              setFieldValue('captureCommission', checked)
                            }
                          />
                        }
                        label="Capture commission"
                      />
                    </div>
                  ) : null}
                  <div className="flex flex-col sm:flex-row gap-x-2 sm:w-ful">
                    {values.modeOfPayment === 'Cheque' ? (
                      <div className="mb-4 w-full">
                        <InputField
                          value={values.chequeNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          errorMessage={errors.chequeNumber}
                          placeholder="Cheque Number"
                          name="chequeNumber"
                        />
                      </div>
                    ) : null}
                    {values.modeOfPayment === 'Bank Transfer' ||
                    values.modeOfPayment === 'Cheque' ? (
                      <div className="mb-4 w-full">
                        <InputField
                          value={values.bank}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          edited={touched}
                          errorMessage={errors.bank}
                          placeholder="Bank Name"
                          name="bank"
                        />
                      </div>
                    ) : null}
                  </div>
                  {isNotDirect ? (
                    <div className="flex flex-col sm:flex-row gap-x-2 sm:w-full">
                      <div className="mb-4 sm:w-full">
                        <SearchSelect
                          value={values.debitAccount}
                          search={handleAccountSearch('debitAccount')}
                          handleChange={handleAccountSelect('debitAccount')}
                          getOptionLabel={(opt) => opt.account_name}
                          getOptionValue={(opt) => opt.account_code_pk}
                          placeholder="Debit Account"
                        />
                      </div>

                      <div className="mb-4 sm:w-full">
                        <InputField
                          inputClassName="h-12"
                          placeholder="Transaction amount"
                          name="amountToPay"
                          onChange={handleChange}
                          money
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.amountToPay}
                          errorMessage={errors.amountToPay}
                        />
                      </div>
                    </div>
                  ) : values.captureCommission ? (
                    <>
                      <div className="flex flex-col sm:flex-row gap-x-2 sm:w-full">
                        <div className="mb-4 sm:w-full">
                          <SearchSelect
                            value={values.debitAccount}
                            search={handleAccountSearch('debitAccount')}
                            handleChange={handleAccountSelect('debitAccount')}
                            getOptionLabel={(opt) => opt.account_name}
                            getOptionValue={(opt) => opt.account_code_pk}
                            placeholder="Debit Account"
                          />
                        </div>
                        <div className="mb-4 sm:w-full">
                          <SearchSelect
                            value={values.creditAccount}
                            search={handleAccountSearch('creditAccount')}
                            handleChange={handleAccountSelect('creditAccount')}
                            getOptionLabel={(opt) => opt.account_name}
                            getOptionValue={(opt) => opt.account_code_pk}
                            placeholder="Credit Account"
                          />
                        </div>
                      </div>
                      <div className="mb-4 sm:w-full">
                        <InputField
                          inputClassName="h-12"
                          placeholder="Commission amount"
                          name="amountToPay"
                          onChange={handleChange}
                          money
                          onBlur={handleBlur}
                          edited={touched}
                          value={values.amountToPay}
                          errorMessage={errors.amountToPay}
                        />
                      </div>
                    </>
                  ) : null}
                  {!direct || values.captureCommission ? (
                    <div className="mb-4">
                      <InputField
                        value={values.transactionDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        edited={touched}
                        errorMessage={errors.transactionDescription}
                        className="mb-4 lg:w-full md:w-full sm:mb-4 md:mb-4"
                        placeholder="Transaction description"
                        name="transactionDescription"
                        maxLength="200"
                      />
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="mb-4 lg:flex lg:justify-between">
                    <InputField
                      inputClassName="h-12"
                      className="w-full"
                      placeholder={
                        direct ? 'Premium Amount' : 'Outstanding Balance'
                      }
                      isDisabled={true}
                      value={formatMoney(
                        invoiceBalance.replace('₦', '	').replace(/,/g, ''),
                        0,
                      )}
                    />
                  </div>
                </div>
                <div className="px-6 py-1 bg-white">
                  <div className="flex justify-end mb-6">
                    <Button
                      type="button"
                      onClick={() => {
                        setClientAccountError(false);
                        resetForm();
                        closeModal();
                      }}
                      name="Cancel"
                      className="p-1 mr-1 text-white bg-red-500 border border-white hover:bg-red-400 focus:border-solid focus:border-gray-300"
                    />
                    <Button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      onClick={handleSubmit}
                      name="Add Payment"
                      className={
                        !isValid
                          ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                          : isSubmitting
                          ? 'text-gray-600 bg-gray-200 cursor-not-allowed w-40 font-bold'
                          : 'bg-blue-850 hover:bg-blue-730 text-white w-40 font-bold'
                      }
                      isLoading={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
}
